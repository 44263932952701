import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import { DateFilter } from '../../components/DateFilter'
import { FloatingButton } from '../../components/FloatingButton'
import { toast } from '../../components/LsToast'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { OrderDto, OrderDtoPickupTimeEnum, PartnerDto, PickupPointDto, ProductDto } from '../../generated/api/breadlove'
import { createOrder, deleteOrder, getOrders, getPartners, patchOrder, updateOrder } from '../../service/orders.service'
import { getPickupPoints } from '../../service/pickup-points.service'
import { getProducts } from '../../service/products.service'
import { areDatesEqualIgnoringTime, getBeforeDays, getNextDays } from '../../utils/date.utils'
import { DeleteModal } from './DeleteModal'
import { OrdersSummary } from './OrdersSummary'
import { OrdersTable } from './OrdersTable'
import { OrderFormValues, SidePanel } from './SidePanel'

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  '&:not(.Mui-selected)': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.875rem',
}))

export const Orders: FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [productsLoading, setProductsLoading] = useState<boolean>(true)
  const [tableLoading, setTableLoading] = useState<boolean>(true)
  const [pointsLoading, setPointsLoading] = useState<boolean>(true)
  const [partnersLoading, setPartnersLoading] = useState<boolean>(true)

  const [date, setDate] = useState<Date>(new Date())
  const [isToday, setIsToday] = useState<boolean>(false)

  const [activeOrder, setActiveOrder] = useState<OrderDto | null>(null)

  const [sidePanelOpen, setSidePanelOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const [products, setProducts] = useState<ProductDto[]>([])
  const [pickupPoints, setPickupPoints] = useState<PickupPointDto[]>([])
  const [orders, setOrders] = useState<OrderDto[]>([])
  const [partners, setPartners] = useState<PartnerDto[]>([])
  const [pickupTime, setPickupTime] = useState<OrderDtoPickupTimeEnum | undefined>()

  const loadProducts = async () => {
    setProductsLoading(true)
    try {
      const res = await getProducts(date)
      setProducts(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    } finally {
      setProductsLoading(false)
    }
  }

  const loadPartners = async () => {
    setPartnersLoading(true)
    try {
      const res = await getPartners()
      setPartners(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    } finally {
      setPartnersLoading(false)
    }
  }

  const loadPickupPoints = async () => {
    setPointsLoading(true)
    try {
      const res = await getPickupPoints()
      setPickupPoints(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    } finally {
      setPointsLoading(false)
    }
  }

  const loadOrders = async () => {
    setTableLoading(true)
    try {
      const res = await getOrders(date, pickupTime)
      setOrders(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    } finally {
      setTableLoading(false)
    }
  }

  const handleAddOrderClicked = () => {
    setActiveOrder(null)
    setSidePanelOpen(true)
  }

  const handleDeleteIconClicked = (order: OrderDto) => {
    setActiveOrder(order)
    setDeleteModalOpen(true)
  }

  const handleUpdateIconClicked = (order: OrderDto) => {
    setActiveOrder(order)
    setSidePanelOpen(true)
  }

  const routeToDate = (date: Date) => {
    navigate({ search: `?date=${date.toISOString()}` })
  }

  const handlePatchIconClicked = async (order: OrderDto) => {
    try {
      await patchOrder(order.id, !order.open)
      toast.success(t('general.notification.savedSuccessfully'))
      void loadProducts()
      void loadOrders()
    } catch (e) {
      toast.error(t('general.notification.unexpectedError'))
    }
  }

  const handleSaveConfirmed = async (form: OrderFormValues) => {
    try {
      if (activeOrder?.id) {
        await updateOrder(activeOrder.id, activeOrder.open, form)
      } else {
        await createOrder(form)
      }
      toast.success(t('general.notification.savedSuccessfully'))
      void loadProducts()
      void loadOrders()
      setSidePanelOpen(false)
      setActiveOrder(null)
    } catch (e) {
      if (e instanceof AxiosError && e.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error(t('general.notification.unexpectedError'))
      }
    }
  }

  const handleDeleteConfirmed = async () => {
    try {
      await deleteOrder(activeOrder!.id)
      toast.success(t('general.notification.deletedSuccessfully'))
      setDeleteModalOpen(false)
      setActiveOrder(null)
      void loadProducts()
      void loadOrders()
    } catch (e) {
      if (e instanceof AxiosError && e.response?.data?.message) {
        toast.error(e.response.data.message)
      } else {
        toast.error(t('general.notification.unexpectedError'))
      }
    }
  }

  const { t } = useTranslation()

  useEffect(() => {
    void loadPickupPoints()
    void loadPartners()
  }, [])

  useEffect(() => {
    const date = searchParams.get('date')
    setDate(date ? new Date(date) : new Date())
  }, [searchParams])

  useEffect(() => {
    setIsToday(areDatesEqualIgnoringTime(date, new Date()))
    void loadProducts()
    void loadOrders()
  }, [date, pickupTime])

  return (
    <ScreenWrapper
      title={t('orders.title')}
      filter={
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <Box>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={pickupTime}
              exclusive
              onChange={(event, value) => setPickupTime(value)}
              aria-label="pickupTime"
              sx={{
                '& .MuiToggleButton-root': {
                  padding: '8px 16px',
                  fontSize: '0.875rem',
                },
              }}
            >
              {[OrderDtoPickupTimeEnum._1, OrderDtoPickupTimeEnum._2, OrderDtoPickupTimeEnum._3].map((time) => (
                <CustomToggleButton value={time}>{t(`orders.table.pickupTime.${time}`)}</CustomToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Button
            variant={isToday ? 'contained' : 'outlined'}
            color={isToday ? 'secondary' : 'primary'}
            aria-label="today"
            size="large"
            onClick={() => routeToDate(new Date())}
          >
            {t('orders.filters.today')}
          </Button>
          <Button variant="outlined" aria-label="tommorow" size="large" onClick={() => routeToDate(getNextDays())}>
            {t('orders.filters.tomorrow')}
          </Button>
          <Button variant="outlined" aria-label="before" size="large" onClick={() => routeToDate(getBeforeDays(date))}>
            <KeyboardArrowLeftIcon />
          </Button>
          <DateFilter value={date} router={routeToDate} />
          <Button variant="outlined" aria-label="next" size="large" onClick={() => routeToDate(getNextDays(date))}>
            <KeyboardArrowRightIcon />
          </Button>
        </Box>
      }
    >
      <Box mt={2}>
        <OrdersSummary products={products} loading={productsLoading} />
      </Box>
      <Box mt={2}>
        <OrdersTable
          orders={orders}
          products={products}
          pickupPoints={pickupPoints}
          onUpdate={handleUpdateIconClicked}
          onDelete={handleDeleteIconClicked}
          onPatch={handlePatchIconClicked}
          loading={tableLoading || pointsLoading || productsLoading || partnersLoading}
        />
      </Box>

      <DeleteModal
        order={activeOrder}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleDeleteConfirmed}
      />

      <SidePanel
        order={activeOrder}
        open={sidePanelOpen}
        products={products}
        pickupPoints={pickupPoints}
        partners={partners}
        onOpen={() => setSidePanelOpen(true)}
        onClose={() => setSidePanelOpen(false)}
        onSave={handleSaveConfirmed}
      />

      <FloatingButton title={t('orders.add')} onClick={handleAddOrderClicked}></FloatingButton>
    </ScreenWrapper>
  )
}
