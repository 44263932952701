import { OrderDtoPickupTimeEnum, OrdersApi } from '../generated/api/breadlove'
import { OrderFormValues } from '../views/Orders/SidePanel'
import axios from './axios'

const ordersApi = new OrdersApi(undefined, process.env.REACT_APP_BE_URL, axios)

export const getOrders = (date: Date, pickupTime: OrderDtoPickupTimeEnum | undefined) => {
  return ordersApi.getAllOrders(date.toISOString(), pickupTime)
}

export const getPartners = () => {
  return ordersApi.getPartners()
}

export const getWeeklySummaryOrders = (date: Date) => {
  return ordersApi.getWeeklySummaryOrders(date.toISOString())
}

export const getPartnersSummaryOrders = (date: Date) => {
  return ordersApi.getPartnersSummaryOrders(date.toISOString())
}

export const createOrder = (form: OrderFormValues) => {
  form.pickupDate.setHours(12)
  return ordersApi.createOrder({
    type: form.type as 'b2b' | 'b2c',
    email: form.email,
    name: form.name,
    note: form.note,
    open: true,
    phoneNumber: form.phoneNumber,
    pickupDate: form.pickupDate.toISOString(),
    pickupTime: form.pickupTime,
    pickupPointId: form.pickupPointId,
    products: form.products.map((item) => ({ productId: item.productId, count: item.count })),
  })
}

export const updateOrder = (id: number, open: boolean, form: OrderFormValues) => {
  form.pickupDate.setHours(12)
  return ordersApi.updateOrder(id, {
    type: form.type as 'b2b' | 'b2c',
    email: form.email,
    name: form.name,
    note: form.note,
    open: open,
    phoneNumber: form.phoneNumber,
    pickupDate: form.pickupDate.toISOString(),
    pickupTime: form.pickupTime,
    pickupPointId: form.pickupPointId,
    products: form.products.map((item) => ({ productId: item.productId, count: item.count })),
  })
}

export const patchOrder = (id: number, open: boolean) => {
  return ordersApi.patchOrder(id, { open: open })
}

export const deleteOrder = (id: number) => {
  return ordersApi.deleteOrder(id)
}
